import React from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';

import {
  Button,
  Checkbox,
  EditorControl,
  errorMessages,
  FormControl,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  StyledCheckboxContainer,
} from '@clatter/ui';
import routes from '../../routes/routes';
import { updatePage } from '../../store';
import RailContent from '../RailContent/RailContent';
import PreviewButton from '../SiteMaker/PreviewButton';
import { getNextPage, isSiteComplete } from '../../helpers';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';

export const defaultRailItem = {
  description: '',
  headline: '',
};

const railItemsObject = {
  headline: Joi.string().min(1).required(),
  description: Joi.string().min(10).required(),
};

const railObject = {
  headline: Joi.string().min(1).required(),
  description: Joi.string().optional().allow(''),
  includeOptionalCopyBlock: Joi.boolean().optional(),
  optionalCopyBlock: Joi.when('includeOptionalCopyBlock', {
    is: true,
    then: Joi.string().max(500).required(),
    otherwise: Joi.string().allow('').optional(),
  }),
  items: Joi.array().optional().items(railItemsObject),
};

const validationSchema = Joi.object()
  .keys({
    rails: Joi.array().items(railObject),
  })
  .messages({
    'any.required': errorMessages.REQUIRED.message,
    'string.uri': errorMessages.INVALID.message,
    'string.empty': errorMessages.REQUIRED.message,
    'string.base': errorMessages.REQUIRED.message,
    'array.base': errorMessages.REQUIRED.message,
    'array.min': errorMessages.REQUIRED.message,
    'string.min': errorMessages.REQUIRED.message,
  })
  .options({
    allowUnknown: true,
  });

const defaultRailsConfig = {
  leftMaxItems: 0,
  centerMaxItems: 0,
};
const PerformanceForm = ({
  currentMicrosite,
  currentPage,
  railsData,
  pageId,
  blockName,
  railsConfig = defaultRailsConfig,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    register,
    watch,
    reset,
  } = useForm({
    resolver: joiResolver(validationSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      rails: railsData,
    },
  });

  const hasItems = watch('rails[0].items')?.length > 0;

  const handleUpdate = async (formData) => {
    if (isValid && isDirty) {
      const variables = JSON.parse(currentPage?.variables);

      const updatedVariables = {
        ...variables,
        [blockName]: formData?.rails || [],
      };

      await dispatch(
        updatePage({
          id: pageId,
          variables: JSON.stringify(updatedVariables),
        }),
      );
    }
  };

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await handleUpdate(formData);
    }
    history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    if (isDirty && isValid) {
      const formData = getValues();
      await handleUpdate(formData);
      reset(formData);
    }
  };

  const handleAddRailItem = (railIndex) => {
    const updated = [
      ...getValues(`rails[${railIndex}].items`),
      {
        ...defaultRailItem,
        id: uuidv4(),
      },
    ];
    setValue(`rails[${railIndex}].items`, updated, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleDeleteRailItem = ({ railIndex, railItemIndex }) => {
    if (window.confirm('Are you sure you want to remove this item?')) {
      let updated = [...getValues(`rails[${railIndex}].items`)];
      updated.splice(railItemIndex, 1);
      setValue(`rails[${railIndex}].items`, updated, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });

      if (updated.length === 0) {
        // hack for form to change isDirty to true when
        // we are setting 'rails[${railIndex}].items' as empty array...
        setValue(`rails.description`, Date.now().toLocaleString(), {
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    }
  };

  const redirectToPublish = () => history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Performance Highlights and continue' : 'Continue'}
      </Button>
      <PreviewButton siteName={currentMicrosite?.name} pageName={currentPage?.name} onPreviewClick={onPreviewClick} />
      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <StepCard>
        <StepCardHeader step={1}>
          <StepCardTitle text={railsConfig?.leftTitle} />
        </StepCardHeader>
        <StepCardContent>
          <RailContent
            index={0}
            control={control}
            errors={errors}
            railsConfig={railsConfig}
            hideCta={railsConfig?.hideLeftCta}
            headline={railsConfig?.leftHeadline}
            maxItems={railsConfig?.leftMaxItems}
            minItems={railsConfig?.leftMinItems}
            itemHeadline={railsConfig?.itemHeadline}
            disableHeadline={railsConfig?.disableLeftHeadline}
            handleAddRailItem={handleAddRailItem}
            handleDeleteRailItem={handleDeleteRailItem}
          />
        </StepCardContent>
      </StepCard>

      {hasItems && (
        <StepCard>
          <StepCardHeader step={2}>
            <StepCardTitle text="Optional copy block" />
          </StepCardHeader>

          <StepCardContent>
            <Stack direction="column" spacing={2}>
              <StyledCheckboxContainer>
                <Checkbox {...register('rails[0].includeOptionalCopyBlock')} text="Include Optional copy block" />
              </StyledCheckboxContainer>

              {watch('rails[0].includeOptionalCopyBlock') && (
                <div>
                  <FormControl label="Copy" error={errors?.rails?.[0]?.optionalCopyBlock}>
                    <Controller
                      name={`rails[0].optionalCopyBlock`}
                      render={({ field: { onChange, value } }) => <EditorControl onChange={onChange} value={value} />}
                      control={control}
                    ></Controller>
                  </FormControl>
                </div>
              )}
            </Stack>
          </StepCardContent>
        </StepCard>
      )}

      <SiteMakerActions renderButtons={renderButtons} />
    </form>
  );
};

export default PerformanceForm;
